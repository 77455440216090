<template>
  <v-form>
    <v-row class="ma-0">

      <v-col cols="5">

        <div>
          <div>Item 1:</div>
          <small class="grey--text">.jpg, .jpeg, .png are accepted</small>
          <div>
            <v-checkbox :value="item_1.image.active" label="Active"/>
          </div>
          <div>
            <v-text-field />
            <!-- <InputImage :formats="image_formats" :value="item_1.image" /> -->
          </div>
          <div>
            <v-text-field outlined dense label="Title"/>
          </div>
          <div>
            <v-text-field  outlined denselabel="Description"/>
          </div>
          <v-divider />


        </div>


      </v-col>

      <v-col cols="7" class="d-flex justify-center align-center">
        <div class="d-flex">
          <v-img class="mr-2" width="80px" height="80px" src="/img/editor-background.png" />
          <v-img class="mr-2" width="80px" height="80px" src="/img/editor-background.png" />
          <v-img class="mr-2" width="80px" height="80px" src="/img/editor-background.png" />
          <v-img class="mr-2" width="80px" height="80px" src="/img/editor-background.png" />
          <v-img class=""     width="80px" height="80px" src="/img/editor-background.png" />
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>


<script>
import InputImage from '@/components/InputImage'
export default {
  name:'BannerEditorCarousel',
  components: {
    InputImage
  },
  props: {
    values: {
      type: [Object],
      default: () => ({})
    }
  },
  data: () => ({
    image_formats: [],

    //
    item_1: {
      active: true,
      images: [],
      image: '',
      title: '$job.title',
      description: '$job.description',
    },
    item_2: {
      active: true,
      images: [],
      image: '',
      title: '$job.title',
      description: '$job.description',
    },
    item_3: {
      active: true,
      images: [],
      image: '',
      title: '$job.title',
      description: '$job.description',
    },
    item_4: {
      active: false,
      images: [],
      image: '',
      title: '$job.title',
      description: '$job.description',
    },
    item_5: {
      active: false,
      images: [],
      image: '',
      title: '$job.title',
      description: '$job.description',
    },

  })

}
</script>
